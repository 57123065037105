import { Component, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { PLUserPhoto } from '../common/abstract/pl-user-photo';
import { MizarService } from '../api/mizar/mizar.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppStore } from '@root/src/app/appstore.model';
import { PLAssumeLoginService } from '../pl-assume-login/pl-assume-login.service';

@Component({
  selector: 'pl-top-bar',
  templateUrl: './pl-top-bar.component.html',
  styleUrls: ['./pl-top-bar.component.less'],
})
export class PLTopBarComponent extends PLUserPhoto implements OnInit {
  @ViewChild('menuContainer') menuContainer: ElementRef;
  @Output() navToggle = new EventEmitter<void>();

  @Input() user: any = {};
  @Input() userMenuLinks: any[];
  @Input() isAssumed: boolean = false;
  @Input() toggleNav: () => void;
  @Input() userType: string;
  @Input() username: string;
  @Input() collapsable: boolean = true;

  homeUrl: string;
  isAssumedLogin: boolean = false;

  constructor(
    private assumeLoginService: PLAssumeLoginService,
    mizarService: MizarService,
    route: ActivatedRoute,
    store: Store<AppStore>,
  ) {
    super(mizarService, route, store);
    this.homeUrl =
      this.userType === 'CAM'
        ? '/cam-dashboard/overview'
        : this.userType === 'CUSTOMER'
        ? '/dashboard'
        : '/landing';

    this.getUserPicture();
  }

  ngOnInit() {
    this.getUserPicture();
    this.assumeLoginService.getAssumedLogin.subscribe(
      (isAssumedLogin: boolean) => {
        this.isAssumedLogin = isAssumedLogin;
      },
    );
  }

  onReleaseClick(): void {
    this.assumeLoginService.release();
  }
}
