<mat-toolbar class="pl-top-bar" [class.assumed]="isAssumed" #menuContainer>
  <div class="title">
    <button
      class="button"
      (click)="navToggle.emit()"
      mat-icon-button
      *ngIf="collapsable"
    >
      <mat-icon class="small-icon">menu</mat-icon>
    </button>
    <a [routerLink]="homeUrl" class="logo">
      <pl-icon
        class="side-nav-logo"
        svg="logo-color-no-tm"
        [width]="'155'"
        [height]="'40'"
        [verticalAlign]="'-5px'"
      ></pl-icon>
    </a>
    <button
      *ngIf="isAssumedLogin"
      mat-stroked-button
      color="primary"
      type="button"
      (click)="onReleaseClick()"
    >
      Release {{ username }}
    </button>
  </div>
  <ng-content></ng-content>
  <button mat-button [matMenuTriggerFor]="userMenu">
    <mat-icon>account_circle_outline</mat-icon>
    <span *ngIf="user?.display_name" class="menu-button">
      {{ user.display_name }}
    </span>
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu class="user-menu" #userMenu="matMenu">
    <div *ngIf="!userHasPicture" class="user-details">
      <div class="initials">
        <span *ngIf="user?.first_name">
          {{ user.first_name.charAt(0) }}
        </span>
        <span *ngIf="user?.last_name">
          {{ user.last_name.charAt(0) }}
        </span>
      </div>
      <div class="display">
        <div class="display-name">{{ user.display_name }}</div>
        <div class="user-name">{{ user.username }}</div>
      </div>
    </div>
    <div *ngIf="userHasPicture" class="user-details">
      <div>
        <img [src]="userPicture" class="profile-picture" />
      </div>
      <div class="display">
        <div class="display-name">{{ user.display_name }}</div>
        <div class="user-name">{{ user.username }}</div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <mat-nav-list>
      <div *ngFor="let links of userMenuLinks" class="item">
        <ng-container *ngFor="let link of links">
          <span *ngIf="link.href; else subMenu">
            <a
              mat-list-item
              *ngIf="link.href.startsWith('http'); else internalLink"
              [href]="link.href"
              target="_blank"
            >
              {{ link.label }}
            </a>
            <ng-template #internalLink>
              <a mat-list-item [routerLink]="link.href">
                {{ link.label }}
              </a>
            </ng-template>
          </span>
          <ng-template #subMenu>
            <button
              mat-menu-item
              [matMenuTriggerFor]="contextMenu"
              (click)="$event.stopPropagation()"
              [matMenuTriggerData]="{ link: link }"
            >
              <span class="context-menu">{{ link.label }}</span>
            </button>
          </ng-template>
        </ng-container>
      </div>
    </mat-nav-list>
  </mat-menu>
  <mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-link="link">
      <button
        mat-menu-item
        (click)="option.action()"
        *ngFor="let option of link.options"
        [disabled]="option.active"
        type="button"
      >
        <strong *ngIf="option.active; else bland">
          {{ option.label }}
        </strong>
        <ng-template #bland>
          {{ option.label }}
        </ng-template>
      </button>
    </ng-template>
  </mat-menu>
</mat-toolbar>
